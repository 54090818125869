import React, { Component } from 'react'
import './ValidateButton.scss'

class ValidateButton extends Component {

  render() {
    return (
      <button disabled={this.props.disabled} className='ValidateButton' onClick={this.props.uploadFiles}>
        Validate
      </button>
    );
  }
}

export default ValidateButton;
