const xmldom = require("xmldom");
const Base64 = require ("base-64");

class EICRResponseMapper {

  results = [];
  reportHeader = {};
  report = {};

  generateJSONReport(data) {

    const jsonResponse = JSON.parse(data);
    //console.log('eicr-responseMapper|generateJSONReport|jsonResponse: ',jsonResponse);
    const xmlMessage = Base64.decode(jsonResponse.message.message)

    const xmlDomParser = xmldom.DOMParser;
    // const data = (new XMLSerializer).serializeToString(xmlDoc);
    const doc = new xmlDomParser().parseFromString(xmlMessage, "application/xml");
    const elements = doc.getElementsByTagName("Report");
    const report = elements[0];

    // console.log("Report: "+JSON.stringify(report));
    this.reportHeader = this.getReportHeader(report);
    this.results = this.getResults(report);

    return { ReportHeader: this.reportHeader, Results: this.results };
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  getReportHeader(report) {
    var reportHeader = {};
    var reportHeaderNode = {};
    var childNodes = {};

    for (var key in report.childNodes) {
      let node = report.childNodes[key];
      if (node.nodeName === "ReportHeader") {
        reportHeaderNode = node;
        childNodes = reportHeaderNode.childNodes;
      }
    }

    for (key in childNodes) {
      let node = childNodes[key];
      if (node.nodeName === "ValidationStatus") {
        if (node.childNodes.length > 0) {
          reportHeader["ValidationStatus"] = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "DateOfTest") {
        if (node.childNodes.length > 0) {
          reportHeader["DateOfTest"] = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "TimeOfTest") {
        if (node.childNodes.length > 0) {
          reportHeader["TimeOfTest"] = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "ResultOfTest") {
        if (node.childNodes.length > 0) {
          reportHeader["ResultOfTest"] = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "ErrorCount") {
        if (node.childNodes.length > 0) {
          reportHeader["ErrorCount"] = node.childNodes[0].data;
        }
      }
    }

    return reportHeader;
  }

  getResults(report) {
    const reportChildNodes = report.childNodes;

    var results = [];
    for (var reportChildNodeKey in reportChildNodes) {
      const node = reportChildNodes[reportChildNodeKey];
      if (node.nodeName === "Results") {
        if (!this.isEmpty(node.childNodes)) {
          results.push(this.createResultsObject(node));
        }
      }
    }
    return results;
  }

  createResultsObject(results) {
    const attributes = this.getResultsAttributes(results);
    const issues = this.getIssues(results);
    const validationResults = this.findValidationResults(results);
    if(validationResults[0] && validationResults[0].issue.severity) {
      attributes['severity'] = validationResults[0].issue.severity;
    }


    const resultsObj = {
      attributes: attributes,
      issues: issues,
      validationResults: validationResults
    };
    return resultsObj;
  }

  createIssueObject(issue) {
    const issueChilcNodes = issue.childNodes;

    let issueObj = { severity: "", message: "", context: "", test: "", specification: "" };

    const attributes = this.getIssueAttributes(issue);
    issueObj.severity = attributes.severity;
    for (var issueChildNodeKey in issueChilcNodes) {
      const node = issueChilcNodes[issueChildNodeKey];

      if (node.nodeName === "message") {
        if (node.childNodes.length > 0) {
          issueObj.message = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "context") {
        if (node.childNodes.length > 0) {
          issueObj.context = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "test") {
        if (node.childNodes.length > 0) {
          issueObj.test = node.childNodes[0].data;
        }
      }
      if (node.nodeName === "specification") {
        if (node.childNodes.length > 0) {
          issueObj.specification = node.childNodes[0].data;
        }
      }
    }

    return issueObj;
  }

  createValidationResultsObject(validationResult) {
    const childNodes = validationResult.childNodes;

    for (var key in childNodes) {
      const node = childNodes[key];
      if (node.nodeName === "issue") {
        const issue = this.createIssueObject(node);
        return { issue: issue };
      }
    }
  }

  getIssues(results) {
    const resultsChildNodes = results.childNodes;
    var issues = [];

    for (var resultsChildNodeKey in resultsChildNodes) {
      const node = resultsChildNodes[resultsChildNodeKey];
      if (node.nodeName === "issue") {
        issues.push(this.createIssueObject(node));
      }
    }
    return issues;
  }

  getWarnings(results) {
    const resultsChildNodes = results.childNodes;
    var warnings = [];
    const attributes = this.getResultsAttributes(results);

    if (attributes["severity"] === "warnings") {
      for (var resultsChildNodeKey in resultsChildNodes) {
        const node = resultsChildNodes[resultsChildNodeKey];
        if (node.nodeName === "issue") {
          warnings.push(this.createIssueObject(node));
        }
      }
    }

    return warnings;
  }

  getNotes(results) {
    const resultsChildNodes = results.childNodes;
    var notes = [];
    const attributes = this.getResultsAttributes(results);

    if (attributes["severity"] === "notes") {
      for (var resultsChildNodeKey in resultsChildNodes) {
        const node = resultsChildNodes[resultsChildNodeKey];
        if (node.nodeName === "issue") {
          notes.push(this.createIssueObject(node));
        }
      }
    }

    return notes;
  }

  findValidationResults(results) {
    const resultsChildNodes = results.childNodes;
    var validationResults = [];

    for (var resultsChildNodeKey in resultsChildNodes) {
      const node = resultsChildNodes[resultsChildNodeKey];
      if (node.nodeName === "validationResult") {
        validationResults.push(this.createValidationResultsObject(node));
      }
    }

    return validationResults;
  }

  getIssueAttributes(results) {
    var attributes = {};
    const attributesNodes = results.attributes;

    for (var attributeKey in attributesNodes) {
      const attributeNode = attributesNodes[attributeKey];

      if (attributeNode.value) {
        attributes[attributeNode.nodeName] = attributeNode.value;
      }
    }
    return attributes;
  }

  getResultsAttributes(results) {
    var attributes = {};
    const attributesNodes = results.attributes;

    for (var attributeKey in attributesNodes) {
      const attributeNode = attributesNodes[attributeKey];

      if (attributeNode.value) {
        attributes[attributeNode.nodeName] = attributeNode.value;
      }
    }

    return attributes;
  }

}

export default EICRResponseMapper;