class ResponseMapper {

  generateJSONReport(data) {
    const jsonResponse = JSON.parse(data);
    const finalResp = JSON.stringify(jsonResponse, undefined, 4);

    const reportJSON = {
      ReportHeader: {
        ValidationStatus: jsonResponse.validation_status
      },
      classifications: {},
      rawData: finalResp
    }

    const affirmative = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    const informational = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    const alert = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    const warning = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    const specError = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    const error = {
      'value-set' : [],
      'structure' : [],
      'content' : []
    }

    console.log('jsonResponse: ',jsonResponse);

    let jsonMessage;
    try {
      jsonMessage = JSON.parse(jsonResponse.message.message);
    } catch {
      jsonMessage = jsonResponse.message;
    }

    try {

      jsonMessage.entries['value-set'].forEach(element => {
        const resp = {
          path : element.path,
          description : element.description,
          line: element.line
        };

        if(element.classification === "Affirmative") { 
          affirmative['value-set'].push(resp);
        }
        if(element.classification === "Informational") { 
          informational['value-set'].push(resp);
        }
        if(element.classification === "Alert") { 
          alert['value-set'].push(resp);
        }
        if(element.classification === "Warning") { 
          warning['value-set'].push(resp);
        }
        if(element.classification === "Spec Error") { 
          specError['value-set'].push(resp);
        }
        if(element.classification === "Error") { 
          error['value-set'].push(resp);
        }
      });
  
      jsonMessage.entries.structure.forEach(element => {
        const resp = {
          path : element.path,
          description : element.description,
          line: element.line
        };
        
        if(element.classification === "Affirmative") { 
          affirmative.structure.push(resp);
        }
        if(element.classification === "Informational") { 
          informational.structure.push(resp);
        }
        if(element.classification === "Alert") { 
          alert.structure.push(resp);
        }
        if(element.classification === "Warning") { 
          warning.structure.push(resp);
        }
        if(element.classification === "Spec Error") { 
          specError.structure.push(resp);
        }
        if(element.classification === "Error") { 
          error.structure.push(resp);
        }
        
      });
  
      jsonMessage.entries.content.forEach(element => {
        const resp = {
          path : element.path,
          description : element.description,
          line: element.line
        };

        if(element.classification === "Affirmative") { 
          affirmative.content.push(resp);
        }
        if(element.classification === "Informational") { 
          informational.content.push(resp);
        }
        if(element.classification === "Alert") { 
          alert.content.push(resp);
        }
        if(element.classification === "Warning") { 
          warning.content.push(resp);
        }
        if(element.classification === "Spec Error") { 
          specError.content.push(resp);
        }
        if(element.classification === "Error") { 
          error.content.push(resp);
        }
        
      });
      
      reportJSON.classifications.affirmative = affirmative;
      reportJSON.classifications.informational = informational;
      reportJSON.classifications.alert = alert;
      reportJSON.classifications.warning = warning;
      reportJSON.classifications.specError = specError;
      reportJSON.classifications.error = error;

    } catch {
      
    }
    
    return reportJSON;

  }

}

export default ResponseMapper;