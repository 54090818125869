import React, { Component } from 'react'
import './FileList.scss'
import FileRow from './FileRow'

class FileList extends Component {

  constructor(props) {
    super(props);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
  }

  deleteFile(fileId) {
    this.props.deleteFile(fileId);
  }

  handleDropdownToggle(fileId) {
    this.props.handleDropdownToggle(fileId);
  }

  render() {
    return (
      <div className='FileList'>
        {Object.keys(this.props.fileItems).map((key, index) => {
          const file = this.props.fileItems[key];
          const fileId = key;
          const name = file.name;
          const size = file.size;
          var report = null;
          if (fileId in this.props.reports) {
            report = this.props.reports[fileId];
          }
          return (
            <FileRow 
              key={key}
              fileId={fileId} 
              fileName={name} 
              fileSize={size} 
              report={report} 
              processing={this.props.processingFiles.includes(fileId)} 
              xmlDoc={this.props.xmlDocs[name]} 
              deleteFile={this.deleteFile}
              handleDropdownToggle={this.handleDropdownToggle}
              showReport={this.props.showReports.includes(fileId)}
            >
            </FileRow>
          );          
        })}
      </div>
    );
  }
}

export default FileList;
