import React, {Component} from 'react';
import './../Report.scss';
import ErrorMessage from './../ErrorMessage';
import dropdownIcon from "./../../../images/Files/dropdownBlue.svg";

class Report extends Component {

    constructor(props) {
        super(props);
        this.getSchemaViolations = this.getSchemaViolations.bind(this);
        this.getSchematronErrors = this.getSchematronErrors.bind(this);
        this.getFatalErrors = this.getFatalErrors.bind(this);
        this.getWarnings = this.getWarnings.bind(this);
        this.getNotes = this.getNotes.bind(this);
        this.showWarnings = false;

        this.SchemaViolationsRef = React.createRef();
        this.FatalErrorsRef = React.createRef();
        this.SchemaErrorsRef = React.createRef();
        this.SevereWarningsRef = React.createRef();
        this.WarningsRef = React.createRef();
        this.NotesRef = React.createRef();

        this.state = {
            showSchemaViolations: true,
            showFatalErrors: true,
            showSchemaErrors: true,
            showSevereWarnings: true,
            showWarnings: false,
            showNotes: false
        };
    }

    getSchemaViolations(report) {
        var errorMessages = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'schemaViolation') {
                    const issues = resultItem.issues;

                    issues.forEach(issue => {
                        errorMessages.push(issue.message);
                    })
                }
            }
        })

        return errorMessages;

    }

    getSchematronErrors(report) {
        var errorMessages = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'errors') {
                    if (resultItem.validationResults.length > 0) {
                        const validationResults = resultItem.validationResults;

                        validationResults.forEach(result => {
                            errorMessages.push(result.issue);
                        })
                    }
                }
            }

        })

        return errorMessages;

    }

    getFatalErrors(report) {
        var errorMessages = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'fatals') {
                    const issues = resultItem.issues;
                    const validationResults = resultItem.validationResults;

                    if (validationResults) {
                        validationResults.forEach(validationResult => {
                            errorMessages.push(validationResult.issue);
                        })
                    }

                    issues.forEach(issue => {
                        errorMessages.push(issue.message);
                    })
                } else if (resultItem.attributes.severity === 'fatalError') {
                    const issues = resultItem.issues;

                    issues.forEach(issue => {
                        errorMessages.push(issue);
                    })
                }
            }

        })

        return errorMessages;

    }

    getWarnings(report) {
        var warningsIssues = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'warnings') {
                    const issues = resultItem.issues;
                    const validationResults = resultItem.validationResults;

                    validationResults.forEach(validationResult => {
                        warningsIssues.push(validationResult.issue);
                    })

                    issues.forEach(issue => {
                        warningsIssues.push(issue);
                    })
                }
            }
        })
        return warningsIssues;
    }

    getSevereWarnings(report) {
        var severeWarningsIssues = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'severe-warnings') {
                    const issues = resultItem.issues;
                    const validationResults = resultItem.validationResults;

                    if (validationResults) {
                        validationResults.forEach(validationResult => {
                            severeWarningsIssues.push(validationResult.issue);
                        })
                    }

                    issues.forEach(issue => {
                        severeWarningsIssues.push(issue);
                    })
                }
            }
        })
        return severeWarningsIssues;
    }

    getNotes(report) {
        var notesIssues = [];
        const results = report.Results;

        results.forEach(resultItem => {
            if ('severity' in resultItem.attributes) {
                if (resultItem.attributes.severity === 'notes') {
                    const issues = resultItem.issues;

                    issues.forEach(issue => {
                        notesIssues.push(issue);
                    })


                }
            }
        })

        return notesIssues;
    }

    scrollToNode(nodeRef) {
        window.scrollTo(0, nodeRef.current.offsetTop);
    }

    render() {
        const report = this.props.report;
        const fatalErrors = this.getFatalErrors(report);
        const schemaViolations = this.getSchemaViolations(report);
        const schematronErrors = this.getSchematronErrors(report);
        const warnings = this.getWarnings(report);
        const severeWarnings = this.getSevereWarnings(report);
        const notes = this.getNotes(report);

        return (
            <div className='Report'>

                <div className='ReportHeader'>
                    <h1 className='ReportTitle'>Validation Results</h1>
                    <h4 className='ReportHeadingTitle'>Validation Status: <span
                        className='ReportHeadingText'>{this.props.report.ReportHeader.ValidationStatus}</span></h4>
                    <h4 className='ReportHeadingTitle'>Date of Test: <span
                        className='ReportHeadingText'>{this.props.report.ReportHeader.DateOfTest}</span></h4>
                    <h4 className='ReportHeadingTitle'>Time of Test: <span
                        className='ReportHeadingText'>{this.props.report.ReportHeader.TimeOfTest}</span></h4>
                </div>

                <div className='ReportSectionSeperator'></div>

                {
                    schemaViolations.length > 0 ?
                        <div className='SchemaViolations'>
                            <div onClick={() => {
                                this.setState({
                                    showSchemaViolations: !this.state.showSchemaViolations
                                }, () => {
                                    this.scrollToNode(this.SchemaViolationsRef);
                                })
                            }} className='SchemaViolationsSectionHeading reportAccordion'>
                                <h3 ref={this.SchemaViolationsRef} className='reportAccordionHeading'>CDA Schema
                                    Violations (errors):</h3>
                                <button
                                    className={`reportAccordionIcon DropdownButton${
                                        this.state.showSchemaViolations ? " DropdownRotate" : ""
                                    }`}
                                >
                                    <img src={dropdownIcon} alt="dropdown icon"/>
                                </button>

                            </div>
                            {this.state.showSchemaViolations && <div>
                                {schemaViolations.map((message, index) => (
                                    <ErrorMessage message={message} key={index}/>
                                ))}
                            </div>}

                        </div>
                        : ''
                }

                {
                    fatalErrors.length > 0 ?
                        <div className='FatalErrors'>
                            <div onClick={() => {
                                this.setState({
                                    showFatalErrors: !this.state.showFatalErrors
                                },() => {
                                    this.scrollToNode(this.FatalErrorsRef);
                                })
                            }} className='FatalErrorsSectionHeading reportAccordion'>
                                <h3 ref={this.FatalErrorsRef} className='reportAccordionHeading'>Schematron Fatal Errors:</h3>
                                <button
                                    className={`reportAccordionIcon DropdownButton${
                                        this.state.showFatalErrors ? " DropdownRotate" : ""
                                    }`}
                                >
                                    <img src={dropdownIcon} alt="dropdown icon"/>
                                </button>
                            </div>
                            {this.state.showFatalErrors && <div>
                                {fatalErrors.map((issue, index) => (
                                    <ErrorMessage message={issue.message} context={issue.context} test={issue.test}
                                                  specification={issue.specification} key={index}/>
                                ))}
                            </div>}
                        </div>
                        : ''
                }

                {
                    warnings.length > 0 &&
                    <div className='Warnings'>
                        <div onClick={() => {
                            this.setState({
                                showSevereWarnings: !this.state.showSevereWarnings
                            },() => {
                                this.scrollToNode(this.SevereWarningsRef);
                            })
                        }} className='SevereWarningsSectionHeading reportAccordion'>
                            <h3 ref={this.SevereWarningsRef} className='reportAccordionHeading'>Schematron Severe
                                Warnings:</h3>
                            <button
                                className={`reportAccordionIcon DropdownButton${
                                    this.state.showSevereWarnings ? " DropdownRotate" : ""
                                }`}
                            >
                                <img src={dropdownIcon} alt="dropdown icon"/>
                            </button>
                        </div>
                        {this.state.showSevereWarnings && <div>
                            {severeWarnings.map((issue, index) => (
                                <ErrorMessage message={issue.message} context={issue.context} test={issue.test}
                                              specification={issue.specification} key={index}/>
                            ))}
                        </div>
                        }

                    </div>

                }

                {
                    schematronErrors.length > 0 ?
                        <div className='SchematronErrors'>
                            <div onClick={() => {
                                this.setState({
                                    showSchemaErrors: !this.state.showSchemaErrors
                                },() => {
                                    this.scrollToNode(this.SchemaErrorsRef);
                                })
                            }} className='SchematronErrorsSectionHeading reportAccordion'>
                                <h3 ref={this.SchemaErrorsRef} className='reportAccordionHeading'>Schematron Errors:</h3>
                                <button
                                    className={`reportAccordionIcon DropdownButton${
                                        this.state.showSchemaErrors ? " DropdownRotate" : ""
                                    }`}
                                >
                                    <img src={dropdownIcon} alt="dropdown icon"/>
                                </button>
                            </div>
                            {this.state.showSchemaErrors && <div>
                                {schematronErrors.map((issue, index) => (
                                    <ErrorMessage message={issue.message} context={issue.context} test={issue.test}
                                                  specification={issue.specification} key={index}/>
                                ))}
                            </div>}
                        </div>
                        : ''
                }

                {
                    warnings.length > 0 ?
                        <div className='Warnings'>
                            <div onClick={() => {
                                this.setState({
                                    showWarnings: !this.state.showWarnings
                                },() => {
                                    this.scrollToNode(this.WarningsRef);
                                })
                            }} className='WarningsSectionHeading reportAccordion'>
                                <h3 ref={this.WarningsRef} className='reportAccordionHeading'>Schematron Warnings:</h3>
                                <button
                                    className={`reportAccordionIcon DropdownButton${
                                        this.state.showSchemaErrors ? " DropdownRotate" : ""
                                    }`}
                                >
                                    <img src={dropdownIcon} alt="dropdown icon"/>
                                </button>
                            </div>
                            {this.state.showWarnings && <div>
                                {warnings.map((issue, index) => (
                                    <ErrorMessage message={issue.message} context={issue.context} test={issue.test}
                                                  specification={issue.specification} key={index}/>
                                ))}
                            </div>
                            }

                        </div>
                        : ''

                }

                {
                    notes.length > 0 ?
                        <div className='Notes'>
                            <div onClick={() => {
                                this.setState({
                                    showNotes: !this.state.showNotes
                                },() => {
                                    this.scrollToNode(this.NotesRef);
                                })
                            }} className='NotesSectionHeading reportAccordion'>
                                <h3 ref={this.NotesRef} className='reportAccordionHeading'>Schematron Notes:</h3>
                                <button
                                    className={`reportAccordionIcon DropdownButton${
                                        this.state.showSchemaErrors ? " DropdownRotate" : ""
                                    }`}
                                >
                                    <img src={dropdownIcon} alt="dropdown icon"/>
                                </button>
                            </div>
                            {this.state.showNotes && <div>
                                {notes.map((issue, index) => (
                                    <ErrorMessage message={issue.message} context={issue.context} test={issue.test}
                                                  specification={issue.specification} key={index}/>
                                ))}
                            </div>}

                        </div>
                        : ''
                }

            </div>
        );
    }
}

export default Report;