import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SettingsIcon from '@material-ui/icons/Settings'
import './Gear.css'

export default ({ showAdvanced, setShowAdvanced, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleGearClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="Gear" {...props}>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleGearClick}>
        <SettingsIcon/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          setShowAdvanced(!showAdvanced)
          handleClose()
        }}>{showAdvanced ? 'Hide Advanced' : 'Show Advanced'}</MenuItem>
      </Menu>
    </div>
  )
}