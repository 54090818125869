class ResponseMapper {

  generateJSONReport(data) {
  
    const reportJSON = {}
    let finalResp;
    try {
      const jsonResponse = JSON.parse(data);
      try {
        finalResp = JSON.stringify(jsonResponse, undefined, 4);
      } catch(ex) {
        finalResp = jsonResponse;
      }
      
  
      const reportJSON = {
        ReportHeader: {
          ValidationStatus: jsonResponse.validation_status
        },
        message: jsonResponse.message,
        rawData: finalResp
      }
  
      //console.log('jsonResponse: ',jsonResponse);
      return reportJSON;

    } catch(ex) {
      const reportJSON = {
        ReportHeader: {
          ValidationStatus: 'EXCEPTION'
        },
        message: `Exception: ${ex}`,
        rawData: finalResp
      }
      return reportJSON;
    }
}

}

export default ResponseMapper;