import React, { Component } from 'react'
import './Dropzone.scss'
import filesIcon from './../../images/Dropzone/files.png'
import hilightedFilesIcon from './../../images/Dropzone/highlightedFiles.png'

class Dropzone extends Component {

  constructor(props) {
    super(props);
    this.state = { highlight: false}
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(e) {
    if (this.props.disabled) return;
    const files = e.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  onDragOver(e) {
    e.preventDefault();

    if (this.props.disabled) return;

    this.setState({ highlight: true });
  }

  onDragLeave() {
    this.setState({ highlight: false });
  }

  onDrop(e) {
    e.preventDefault();
    this.setState({ highlight: false });

    if (this.props.disabled) return;

    const files = e.dataTransfer.files;
    // console.log(files);
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
      
    }
  }

  render() {
    return (
      <div
      className={`Dropzone ${this.state.highlight ? 'Highlight' : ''}`}
      onDragOver={this.onDragOver}
      onDragLeave={this.onDragLeave}
      onDrop={this.onDrop} 
      onClick={this.openFileDialog} 
      style={{ cursor: this.props.disabled ? 'default' : 'pointer'}}>
        <div className='Dropzone-Container'>
          <img className='FilesIcon' src={this.state.highlight ? hilightedFilesIcon : filesIcon } alt='files icon'/>
          <p className='Dropzone-Title'>Drop files here</p>
          <p className='Dropzone-Subtitle'>or click <span className='Browse'>browse</span></p> 
          <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        </div>
        
      </div>
    );
  }
}

export default Dropzone;