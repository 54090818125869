import React from "react";
import "./FileRow.scss";
import fileIcon from "./../../images/Files/file.png";
import deleteIcon from "./../../images/Files/delete.png";
import dropdownIcon from "./../../images/Files/dropdownBlue.svg";
import downloadIcon from "./../../images/Files/download.png";

import FileSaver from "file-saver";

const humanFileSize = (size) => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

const FileRow = ({
  xmlDoc,
  deleteFile,
  fileId,
  fileName,
  fileSize,
  report,
  showReport,
  processing,
  handleDropdownToggle,
}) => {
  const handleDownload = () => FileSaver.saveAs(xmlDoc, "data.xml");
  const handleDelete = () => deleteFile(fileId);

  return (
    <div className="FileContainer">
      <div className="FileRow">
        <img src={fileIcon} alt="file icon" />
        <p className="FileName">{fileName}</p>
        <p className="FileSize">{humanFileSize(fileSize)}</p>
        <div className="FileButtonsContainer">
          {report ? (
            <div className="Dropdown">
              <button
                onClick={() => handleDropdownToggle(fileId)}
                className={`DropdownButton${
                  showReport ? " DropdownRotate" : ""
                }`}
              >
                <span className="DropdownButtonTitle">
                  {showReport ? "Hide Results" : "View Results"}
                </span>
                <img src={dropdownIcon} alt="dropdown icon" />
              </button>
            </div>
          ) : processing ? (
            <svg className="spinner" viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          ) : (
            <button
              onClick={xmlDoc ? handleDownload : handleDelete}
              className="DeleteButton"
            >
              <img src={xmlDoc ? downloadIcon : deleteIcon} alt="delete icon" />
            </button>
          )}
        </div>
      </div>
      {showReport ? report : ""}
    </div>
  );
};

export default FileRow;
