import React, { Component } from 'react';
import './../Report.scss';
import dropdownIcon from "./../../../images/Files/dropdownBlue.svg";
import Base64 from "base-64";

//https://www.npmjs.com/package/react-syntax-highlighter
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class Report extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showErrorData: false,
      showWarningData: false,
      showSuccessData: false,
      showRawData: this.props.show_classifications.check_raw_data,
    }

    this.RawDataRef = React.createRef();
    this.ErrorDataRef = React.createRef();
    this.WarningDataRef = React.createRef();
    this.SuccessDataRef = React.createRef();

  }

  scrollToNode(nodeRef) {
    window.scrollTo(0, nodeRef.current.offsetTop);
  }

  isClassificationMappingEmpty(classificationType) {
    if (classificationType === null) {
      return true;
    }
    if (classificationType.base64Content === null) {
      return true;
    }
    if (classificationType.base64Content === 'TGluZU51bWJlcixFcnJvckNvZGUsRXJyb3JEZXNjcmlwdGlvbg0K'
      || classificationType.base64Content === 'TGluZU51bWJlcixXYXJuaW5nQ29kZSxXYXJuaW5nRGVzY3JpcHRpb24NCg=='
      || classificationType.base64Content === 'TGluZU51bWJlcixFcnJvcnMsV2FybmluZ3MNCg==') {
      return true;
    }
    return false;
  }

  isIncludeRawData() {
    if (this.props.show_classifications.check_raw_data === true) {
      return true;
    }
    return false;
  }

  countClassificationType(classificationType) {
    if (classificationType === null) {
      return 0;
    } else {
      return this.countNewLines(this.decodeBase64(classificationType));
    }
  }

  decodeBase64(encodedData) {
    console.log('encodedData:', encodedData);
    if (encodedData === null) {
      return "null";
    } else {
      return Base64.decode(encodedData);
    }
  }

  countNewLines(str) {
    return str.split(/\r\n|\r|\n/).length - 2; //minus 2 for header line and newline added in 'empty' response
  }

  /**
   * Render
   */
  render() {

    const report = this.props.report;

    return (
      <div className='Report'>
        <div className='ReportHeader'>
          <h1 className='ReportTitle'>Validation Results</h1>
          <h4 className='ReportHeadingTitle'>Validation Status: <span
            className='ReportHeadingText'>{report.ReportHeader.ValidationStatus}</span></h4>
        </div>

        <div className='ReportSectionSeperator'></div>

        {
          report.classifications.error && !this.isClassificationMappingEmpty(report.classifications.error) && (
            <div className="Errors">
              <div onClick={() => {
                this.setState({
                  showErrorData: !this.state.showErrorData
                }, () => {
                  this.scrollToNode(this.ErrorDataRef);
                })
              }} className='ErrorsSectionHeading reportAccordion'>
                <h3 ref={this.ErrorDataRef} className='reportAccordionHeading'>Errors: {this.countClassificationType(report.classifications.error.base64Content)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showErrorData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showErrorData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {this.decodeBase64(report.classifications.error.base64Content)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          report.classifications.warning && !this.isClassificationMappingEmpty(report.classifications.warning) && (
            <div className="Warnings">
              <div onClick={() => {
                this.setState({
                  showWarningData: !this.state.showWarningData
                }, () => {
                  this.scrollToNode(this.WarningDataRef);
                })
              }} className='WarningsSectionHeading reportAccordion'>
                <h3 ref={this.WarningDataRef} className='reportAccordionHeading'>Warnings: {this.countClassificationType(report.classifications.warning.base64Content)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showWarningData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showWarningData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {this.decodeBase64(report.classifications.warning.base64Content)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          report.classifications.success && !this.isClassificationMappingEmpty(report.classifications.success) && (
            <div className="Success">
              <div onClick={() => {
                this.setState({
                  showSuccessData: !this.state.showSuccessData
                }, () => {
                  this.scrollToNode(this.SuccessDataRef);
                })
              }} className='SuccessSectionHeading reportAccordion'>
                <h3 ref={this.SuccessDataRef} className='reportAccordionHeading'>Success: {this.countClassificationType(report.classifications.success.base64Content)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showSuccessData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showSuccessData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {this.decodeBase64(report.classifications.success.base64Content)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.isIncludeRawData() &&
          <div className='RawData'>
            <div onClick={() => {
              this.setState({
                showRawData: !this.state.showRawData
              }, () => {
                this.scrollToNode(this.RawDataRef);
              })
            }} className='RawDataSectionHeading reportAccordion'>
              <h3 ref={this.RawDataRef} className='reportAccordionHeading'>Raw Data:</h3>
              <button
                className={`reportAccordionIcon DropdownButton${this.state.showRawData ? " DropdownRotate" : ""
                  }`}
              >
                <img src={dropdownIcon} alt="dropdown icon" />
              </button>
            </div>
            {this.state.showRawData && <div>
              <SyntaxHighlighter language="javascript" style={docco}>
                {report.rawData}
              </SyntaxHighlighter>
            </div>}
          </div>

        }

      </div>
    );
  }

}

export default Report;