import React from "react";
import Alert from 'react-bootstrap/Alert';

const AlertDisplay = ({ alertDetails }) => (
  
  <div className="AlertDisplay">
    
    {console.log('AlertDisplay|alertDetails:',alertDetails)}
    {  
      alertDetails && alertDetails.header && alertDetails.message && (
        <Alert variant="danger">
          <Alert.Heading>{alertDetails.heading}</Alert.Heading>
          <p>{alertDetails.message}</p>
          <p>PLEASE REFRESH THE PAGE</p>
        </Alert>
    )}
  </div>

)

export default AlertDisplay