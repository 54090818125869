import React from 'react'
import { version } from './../../../package.json'
import './AppVersion.scss'

const AppVersion = ({ className }) => {

  return (
    <div className={`AppVersion ${className}`}>
      <p>v{version}</p>
    </div>
  )

}

export default AppVersion
