import React, { Component } from 'react'
import './ErrorMessage.scss'

class ErrorMessage extends Component {


  render() {
    return (
      <div className='ErrorMessage'>
        <div className='ErrorMessageSection'>
          <p className='ErrorMessageHeading'>Message</p>
          <p className='ErrorMessageText'>{this.props.message}</p>
        </div>
        {
          this.props.context ?

          <div className='ErrorMessageSection'>
            <p className='ErrorMessageHeading'>Context</p>
            <p className='ErrorMessageText'>{this.props.context}</p>
          </div>

          : ''
        }
        {
          this.props.context ?

          <div className='ErrorMessageSection'>
            <p className='ErrorMessageHeading'>Test</p>
            <p className='ErrorMessageText'>{this.props.test}</p>
          </div>

          : ''
        }
        {
          this.props.specification ?

          <div className='ErrorMessageSection'>
            <p className='ErrorMessageHeading'>Specification</p>
            <p className='ErrorMessageText'>{this.props.specification}</p>
          </div>

          : ''
        }

      </div>
    );
  }
}

export default ErrorMessage;