import React, { Component } from 'react';
import './../Report.scss';
import dropdownIcon from "./../../../images/Files/dropdownBlue.svg";

//https://www.npmjs.com/package/react-syntax-highlighter
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
var JSONBeautify = require("json-beautify");

class Report extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showErrorData: false,
      showSpecErrorData: false,
      showAlertData: false,
      showWarningData: false,
      showInformationalData: false,
      showAffirmativeData: false,
      showRawData: false,
    }

    this.RawDataRef = React.createRef();
    this.ErrorDataRef = React.createRef();
    this.SpecErrorDataRef = React.createRef();
    this.AlertDataRef = React.createRef();
    this.WarningDataRef = React.createRef();
    this.InformationalDataRef = React.createRef();
    this.AffirmativeDataRef = React.createRef();

  }

  scrollToNode(nodeRef) {
    window.scrollTo(0, nodeRef.current.offsetTop);
  }

  isClassificationMappingEmpty(classificationType) {
    if (classificationType === null) {
      return true;
    }
    if (classificationType['value-set'] === null
      && classificationType.structure === null
      && classificationType.content === null) {
      return true;
    }
    if (classificationType['value-set'].length === 0
      && classificationType.structure.length === 0
      && classificationType.content.length === 0) {
      return true;
    }
    return false;
  }

  isIncludeRawData() {
    if (this.props.show_classifications.check_raw_data === true) {
      return true;
    }
    return false;
  }

  countClassificationType(classificationType) {
    return classificationType['value-set'].length + classificationType.structure.length + classificationType.content.length;
  }

  /**
   * Render
   */
  render() {

    const report = this.props.report;

    return (
      <div className='Report'>
        <div className='ReportHeader'>
          <h1 className='ReportTitle'>Validation Results</h1>
          <h4 className='ReportHeadingTitle'>Validation Status: <span
            className='ReportHeadingText'>{report.ReportHeader.ValidationStatus}</span></h4>
        </div>

        <div className='ReportSectionSeperator'></div>

        {
          report.message && !this.isClassificationMappingEmpty(report.message) && (
            <div className="Errors">
              <div onClick={() => {
                this.setState({
                  showErrorData: !this.state.showErrorData
                }, () => {
                  this.scrollToNode(this.ErrorDataRef);
                })
              }} className='ErrorsSectionHeading reportAccordion'>
                <h3 ref={this.ErrorDataRef} className='reportAccordionHeading'>Errors: {this.countClassificationType(report.classifications.error)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showErrorData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showErrorData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.error, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.props.show_classifications.check_spec_errors && report.classifications.specError && !this.isClassificationMappingEmpty(report.classifications.specError) && (
            <div className="SpecErrors">
              <div onClick={() => {
                this.setState({
                  showSpecErrorData: !this.state.showSpecErrorData
                }, () => {
                  this.scrollToNode(this.SpecErrorDataRef);
                })
              }} className='ErrorsSectionHeading reportAccordion'>
                <h3 ref={this.SpecErrorDataRef} className='reportAccordionHeading' title='Spec Errors'>Spec Warnings: {this.countClassificationType(report.classifications.specError)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showSpecErrorData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showSpecErrorData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.specError, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.props.show_classifications.check_alerts && report.classifications.alert && !this.isClassificationMappingEmpty(report.classifications.alert) && (
            <div className="Alerts">
              <div onClick={() => {
                this.setState({
                  showAlertData: !this.state.showAlertData
                }, () => {
                  this.scrollToNode(this.AlertDataRef);
                })
              }} className='AlertsSectionHeading reportAccordion'>
                <h3 ref={this.AlertDataRef} className='reportAccordionHeading'>Alerts: {this.countClassificationType(report.classifications.alert)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showAlertData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showAlertData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.alert, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          report.classifications.warning && !this.isClassificationMappingEmpty(report.classifications.warning) && (
            <div className="Warnings">
              <div onClick={() => {
                this.setState({
                  showWarningData: !this.state.showWarningData
                }, () => {
                  this.scrollToNode(this.WarningDataRef);
                })
              }} className='WarningsSectionHeading reportAccordion'>
                <h3 ref={this.WarningDataRef} className='reportAccordionHeading'>Warnings: {this.countClassificationType(report.classifications.warning)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showWarningData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showWarningData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.warning, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.props.show_classifications.check_informational && report.classifications.informational && !this.isClassificationMappingEmpty(report.classifications.informational) && (
            <div className="Informational">
              <div onClick={() => {
                this.setState({
                  showInformationalData: !this.state.showInformationalData
                }, () => {
                  this.scrollToNode(this.InformationalDataRef);
                })
              }} className='InformationalSectionHeading reportAccordion'>
                <h3 ref={this.InformationalDataRef} className='reportAccordionHeading'>Informational: {this.countClassificationType(report.classifications.informational)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showInformationalData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showInformationalData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.informational, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.props.show_classifications.check_affirmatives && report.classifications.affirmative && !this.isClassificationMappingEmpty(report.classifications.affirmative) && (
            <div className="Affirmative">
              <div onClick={() => {
                this.setState({
                  showAffirmativeData: !this.state.showAffirmativeData
                }, () => {
                  this.scrollToNode(this.AffirmativeDataRef);
                })
              }} className='AffirmativeSectionHeading reportAccordion'>
                <h3 ref={this.AffirmativeDataRef} className='reportAccordionHeading'>Affirmative: {this.countClassificationType(report.classifications.affirmative)}</h3>
                <button
                  className={`reportAccordionIcon DropdownButton${this.state.showAffirmativeData ? " DropdownRotate" : ""
                    }`}
                >
                  <img src={dropdownIcon} alt="dropdown icon" />
                </button>
              </div>
              {this.state.showAffirmativeData && <div>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSONBeautify(report.classifications.affirmative, null, 2, 100)}
                </SyntaxHighlighter>
              </div>}

            </div>
          )
        }

        {
          this.isIncludeRawData() &&
          <div className='RawData'>
            <div onClick={() => {
              this.setState({
                showRawData: !this.state.showRawData
              }, () => {
                this.scrollToNode(this.RawDataRef);
              })
            }} className='RawDataSectionHeading reportAccordion'>
              <h3 ref={this.RawDataRef} className='reportAccordionHeading'>Raw Data:</h3>
              <button
                className={`reportAccordionIcon DropdownButton${this.state.showRawData ? " DropdownRotate" : ""
                  }`}
              >
                <img src={dropdownIcon} alt="dropdown icon" />
              </button>
            </div>
            {this.state.showRawData && <div>
              <SyntaxHighlighter language="javascript" style={docco}>
                {report.rawData}
              </SyntaxHighlighter>
            </div>}
          </div>

        }

      </div>
    );
  }

}

export default Report;