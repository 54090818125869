import React, { Component } from 'react'
import './RefreshSchemasButton.scss'

class RefreshSchemasButton extends Component {

  render() {
    return (
      <button className='RefreshSchemasButton' onClick={this.props.refreshSchemas}>
        Refresh Schemas
      </button>
    );
  }
}

export default RefreshSchemasButton;
