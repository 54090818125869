import React, { Component } from 'react'
import './ResetButton.scss'

class ResetButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.reset();
  }

  render() {
    return (
      <button onClick={this.handleClick} className='ResetButton'>Reset</button>
    );
  }
}

export default ResetButton;