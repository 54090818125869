import React from 'react';
import './Report.scss';

//https://www.npmjs.com/package/react-syntax-highlighter
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const RawReport = ({ rawData }) => (
  <div className="Report">
    <div className="ReportHeader">
      <h1 className="ReportTitle">Validation Raw Results</h1>
    </div>
    <div className="ReportSectionSeperator"></div>

    {console.log('RawReport|rawData:',rawData)}
    {  
      rawData && (
      <div className="RawData">
        <h3 className="RawDataSectionHeading">Raw Data</h3>
        <SyntaxHighlighter language="javascript" style={docco}>
          {rawData}
        </SyntaxHighlighter>
      </div>
    )}

  </div>
)

export default RawReport
