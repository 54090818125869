import React from "react";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, MenuItem, Select, } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./Header.scss";
import AppVersion from './../AppVersion/AppVersion';
import { Col, Row } from "react-bootstrap";


const ThemedCheckbox = withStyles({
    root: {
        color: "#00a0af",
        "&$checked": {
            color: "#00a0af",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const header = ({
    schemaDetails = [],
    validatorMenuItems = [],
    schema = 0,
    validatorsDetails = [],
    validator = 0,
    updateValidator,
    updateSchema,
    validatorsSchemaLists,
    show_classifications: { check_spec_errors, check_alerts, check_informational, check_affirmatives, check_raw_data },
    toggleShowClassifications,
    showAdvanced = false
}) => {
    const onSelectChange = (e) => updateSchema(e.target.value);
    const onValidatorRadioButtonChange = (e) => updateValidator(e.target.value);

    if (!showAdvanced) {
        check_spec_errors = true;
        check_alerts = false;
        check_informational = false;
        check_affirmatives = false;
        check_raw_data = false;
    }

    //Get Validators
    validatorsDetails.forEach((item, index) => {
        console.log('HEADER|validatorsDetails|' + JSON.stringify(item) + ' ' + index);

        validatorMenuItems.push(
            <MenuItem key={'validator' + index} value={item.name}>
                <div className="menu-item">
                    {item.name}
                    <p className="validator-description">
                        {item.desc}
                    </p>
                </div>
            </MenuItem>
        )
    });

    //Clear schema details
    schemaDetails = [];
    //Get Schemas for Validator selected
    validatorsSchemaLists.forEach((item, index) => {

        if (item.name === validator) {

            const schemaList = item.schemaList;

            schemaList.forEach((schemaItem, schemaIndex) => {

                const key = Object.keys(schemaItem)[0];
                schemaDetails.push(
                    <MenuItem key={key} value={schemaIndex}>
                        <div className="menu-item">
                            {key + " - " + schemaItem[key].properties.date_created}
                            <p className="schema-description">
                                {schemaItem[key].properties.desc}
                            </p>
                        </div>
                    </MenuItem>
                )

            });

        }

    });

    return (
        <div className="Header">
            <div className="Header-Container">
                <div className="Title">
                    <h2 className="Title">Online Validation Portal</h2>
                    <AppVersion className="Subtitle" />
                </div>
                
                <div className="filter-container">
                    <div className="filter-row">
                        <div className="filter-form-label">Validator:</div>
                        <div className="select-container filter-flex-column">
                            <Select defaultValue="" className="select" style={{
                                width: '400px'
                            }} value={validator} onChange={onValidatorRadioButtonChange}>
                                {validatorMenuItems}
                            </Select>
                        </div>
                    </div>

                    &nbsp;
                    <div className="filter-row">
                        <div className="filter-form-label">Schema:</div>
                        <div className="select-container filter-flex-column">
                            <Select defaultValue="" className="select" style={{
                                width: '400px'
                            }} value={schema} onChange={onSelectChange}>
                                {schemaDetails}
                            </Select>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                {showAdvanced &&
                                    <div className="filter-container">
                                        <FormGroup row>
                                            <FormLabel className="filter-form-label">Classifications</FormLabel>
                                            <FormControlLabel
                                                label="Spec Errors"
                                                control={
                                                    <ThemedCheckbox
                                                        name="check_spec_errors"
                                                        checked={check_spec_errors}
                                                        onChange={toggleShowClassifications}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Alerts"
                                                control={
                                                    <ThemedCheckbox
                                                        name="check_alerts"
                                                        checked={check_alerts}
                                                        onChange={toggleShowClassifications}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Informational"
                                                control={
                                                    <ThemedCheckbox
                                                        name="check_informational"
                                                        checked={check_informational}
                                                        onChange={toggleShowClassifications}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Affirmatives"
                                                control={
                                                    <ThemedCheckbox
                                                        name="check_affirmatives"
                                                        checked={check_affirmatives}
                                                        onChange={toggleShowClassifications}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Raw Data"
                                                control={
                                                    <ThemedCheckbox
                                                        name="check_raw_data"
                                                        checked={check_raw_data}
                                                        onChange={toggleShowClassifications}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default header;
