class CSVFlatFileResponseMapper {

  generateJSONReport(data) {
    const jsonResponse = JSON.parse(data);
    const finalResp = JSON.stringify(jsonResponse, undefined, 4);

    const reportJSON = {
      ReportHeader: {
        ValidationStatus: jsonResponse.validation_status
      },
      classifications: {},
      rawData: finalResp
    }

    const success = {
      base64Content:  null
    }

    const warning = {
      base64Content:  null
    }

    const error = {
      base64Content:  null
    }

    console.log('jsonResponse: ',jsonResponse);

    const jsonMessage = jsonResponse.message;

    if(jsonMessage) {

      if(jsonMessage.successFile) {
        success.base64Content = jsonMessage.successFile;
      }
      if(jsonMessage.warningFile) {
        warning.base64Content = jsonMessage.warningFile;
      }
      if(jsonMessage.errorFile) {
        error.base64Content = jsonMessage.errorFile;
      }
      
      reportJSON.classifications.success = success;
      reportJSON.classifications.warning = warning;
      reportJSON.classifications.error = error;

    } else {
      reportJSON.rawData = jsonResponse;
    }
    

    return reportJSON;

  }

}

export default CSVFlatFileResponseMapper;