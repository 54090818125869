import React, { Component } from 'react'
import './ClearButton.scss'

class ClearButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.reset();
  }

  render() {
    return (
      <button onClick={this.handleClick} className='ClearButton'>Clear</button>
    );
  }
}

export default ClearButton;